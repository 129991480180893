import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddItemPage from './pages/AddItem';
import PaymentPage from './pages/Payment';
import CreateNewUser from './pages/CreateNewUser';
import Players from './pages/Players';
import Predictions from './pages/Predictions';
import Questions from './pages/Questions';
import MasterTable from './pages/MasterTable';

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/add-item' element={<AddItemPage />} />
        <Route path='/payment' element={<PaymentPage />} />
        <Route path='/create-new-user' element={<CreateNewUser />} />
        <Route path='/players' element={<Players />} />
        <Route path='/predictions' element={<Predictions />} />
        <Route path='/questions' element={<Questions />} />
        <Route path='/master-table' element={<MasterTable />} />
      </Routes>
    </div>
  );
}

export default App;

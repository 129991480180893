import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import { useGetAllQuestionsQuery, useGetAllPlayersQuery } from "../redux/services/playerService";

const Questions = () => {
    const { data, isLoading, isError } = useGetAllQuestionsQuery();
    const { data: playerData } = useGetAllPlayersQuery();

    const [newData, setNewData] = useState([]);

    useEffect(() => {
        if (data && playerData) {
            // Create a map of playerId to playerName for efficient lookup
            const playerMap = playerData.reduce((acc, player) => {
                acc[player._id] = player.name;
                return acc;
            }, {});

            const newData = data.map((question) => {
                if(question.optionType === "player" || question.optionType === "team") {
                    const updatedOptions = question.options.map((option) => {
                        return playerMap[option] || "Unknown Player";
                    });
                    return { ...question, options: updatedOptions };
                }
                return question;
            })
            setNewData(newData);
        }
    }, [data, playerData]); 

    return <div>
        <Navbar />
        {isLoading && <Loader />}
        {isError && <div>Error fetching questions</div>}

        <table className="max-w-[1000px] mx-auto">
            <thead>
                <tr className="border-b border-gray-200">
                    <th className="text-left p-3 px-5">S.No</th>
                    <th className="text-left p-3 px-5">Id</th>
                    <th className="text-left p-3 px-5">Question</th>
                    <th className="text-left p-3 px-5">Options</th>
                    <th className="text-left p-3 px-5">Option Type</th>
                </tr>
            </thead>
            {newData && newData.map((question, index) => (
                <tbody>
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                        <td className="p-3 px-5">{index + 1}</td>
                        <td className="p-3 px-5">{question._id}</td>
                        <td className="p-3 px-5 min-w-[200px]">{question.question}</td>
                        <td className="p-3 px-5">
                            {question.options.map((option, index) => (
                                <div key={index}>{index}. {option}</div>
                            ))}
                        </td>
                        <td className="p-3 px-5">{question.optionType}</td>
                    </tr>
                </tbody>
            ))}
        </table>
    </div>
}

export default Questions;
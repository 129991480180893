import { useAddItemMutation } from "../redux/services/itemService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Navbar from "../components/Navbar";

const AddItemPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        price: "",
        description: "",
        category: "",
        isAvailable: true,
        type: "veg"
    });
    const [addItem, { isLoading, error }] = useAddItemMutation();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData({ ...formData,
            [name]: type === "checkbox" ? checked : value
        });
    }

    const handleToggle = () => {
        setFormData({ ...formData, type: formData.type === "veg" ? "non-veg" : "veg" });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const token = Cookies.get("token");
        if(!token) {
            navigate("/login");
        }

        try {
            await addItem(formData);

            setFormData({
                name: "",
                price: "",
                description: "",
                category: "",
                isAvailable: true,
                type: "veg"
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Navbar />
            <div className="flex flex-col items-center justify-center p-4">
                <h1 className="text-2xl font-semibold mb-4">Add New Item</h1>

            <form className="flex flex-col gap-4 max-w-md w-full" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="p-2 rounded-md outline-none border-2 border-gray-300"
                    placeholder="Grilled Cheese Sandwich"
                    required
                />

                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="p-2 rounded-md outline-none border-2 border-gray-300"
                    placeholder="Description"
                    rows="3"
                    required
                />

                <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    className="p-2 rounded-md outline-none border-2 border-gray-300"
                    placeholder="Price"
                    min="0"
                    required
                />

                <input
                    type="text"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    className="p-2 rounded-md outline-none border-2 border-gray-300"
                    placeholder="Sandwich"
                    required
                />

                <label className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        name="isAvailable"
                        checked={formData.isAvailable}
                        onChange={handleChange}
                    />
                        Available
                    </label>

                    <div className="flex items-center">
                        <button
                            type="button"
                            onClick={handleToggle}
                            className={`px-2 py-1 rounded-md text-sm transition-colors ${formData.type === "veg" ? "bg-green-500 text-white" : "bg-red-500 text-white"}`}
                        >
                            {formData.type.charAt(0).toUpperCase() + formData.type.slice(1)}
                        </button>
                    </div>

                    <button
                    type="submit"
                    className="bg-blue-500 text-white p-2 rounded-md"
                    disabled={isLoading}
                >
                    {isLoading ? "Adding..." : "Add Item"}
                </button>
            </form>

            {error && (
                <p className="text-red-500 mt-4">
                    {error.data?.message || "Something went wrong"}
                </p>
            )}
            </div>
        </div>
    )
}

export default AddItemPage;


import { useState, useEffect } from "react";
import OTPInput from "react-otp-input";
import { useLazyGetCardBalanceQuery, useLazyGetCardBalanceByPhoneQuery, usePayWithMobileMutation, useGenerateOtpMutation, usePayWithCashMutation, usePayWithCardMutation } from "../redux/services/cardService";
import { useCreateOrderMutation } from "../redux/services/orderService";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFBill from "./BillPdf";

const PaymentModal = ({ isOpen, onClose, paymentMethod, setSelectedPaymentMethod, cartItems, restaurantDetails }) => {
    const [phone, setPhone] = useState("");
    const [showOtpbtn, setShowOtpbtn] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [cashPhone, setCashPhone] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [orderReset, setOrderReset] = useState(false);
    const [disablePay, setDisablePay] = useState({
        card: true,
        mobile: true,
        cash: true
    });
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);

    const [generateOtp, { isLoading: isOtpLoading }] = useGenerateOtpMutation();
    const [payWithMobile, { isLoading: isMobilePaymentLoading, isSuccess: isMobilePaymentSuccess, error: mobilePaymentError }] = usePayWithMobileMutation();
    const [payWithCash, { isLoading: isCashPaymentLoading, isSuccess: isCashPaymentSuccess, error: cashPaymentError }] = usePayWithCashMutation();
    const [payWithCard, { isLoading: isCardPaymentLoading, isSuccess: isCardPaymentSuccess, error: cardPaymentError }] = usePayWithCardMutation();
    const [fetchCardBalanceByPhone, { isLoading: isCardBalancePhoneLoading, isSuccess: isCardBalancePhoneSuccess, error: cardBalancePhoneError }] = useLazyGetCardBalanceByPhoneQuery();
    const [fetchCardBalanceByCard, { isLoading: isCardBalanceCardLoading, isSuccess: isCardBalanceCardSuccess, error: cardBalanceCardError }] = useLazyGetCardBalanceQuery();

    const [cardDetails, setCardDetails] = useState({
        cardNumber: "",
        isBlocked: false,
        amount: 0,
        redeemPoints: 0,
        applicableCashback: 0,
        userId: "",
        cardId: ""
    });

    const handlePhoneChange = async (e) => {
        const value = e.target.value;
        setPhone(value);
        setCardNumber("");
        setCashPhone("");
        if (value.length === 10 && !isNaN(value)) {
            setShowOtpbtn(true);
        }
        else {
            setShowOtpbtn(false);
            setCardDetails({
                cardNumber: "",
                isBlocked: false,
                amount: 0,
                redeemPoints: 0,
                applicableCashback: 0,
                userId: "",
                cardId: ""
            });
        }
    };

    useEffect(() => {
        const fetchCardDetails = async () => {
            if (phone.length === 10 && !isNaN(phone)) {
                try {
                    const { data } = await fetchCardBalanceByPhone(phone);
                    if (data && data?.card) {
                        setCardDetails({
                            cardNumber: data?.card?.cardNumber,
                            isBlocked: data?.card?.isBlocked,
                            amount: data?.card?.amount,
                            redeemPoints: data?.card?.redeemPoints,
                            applicableCashback: data?.card?.applicableCashback,
                            userId: data?.card?.userId,
                            cardId: data?.card?._id
                        });
                    } else {
                        throw new Error("No card details found");
                    }
                } catch (error) {
                    console.log("Error fetching card details", error);
                    setCardDetails({
                        cardNumber: "",
                        isBlocked: false,
                        amount: 0,
                        redeemPoints: 0,
                        applicableCashback: 0,
                        userId: "",
                        cardId: ""
                    });
                }
            }
            else if (paymentMethod === "mobile" && phone.length !== 10) {
                setCardDetails({
                    cardNumber: "",
                    isBlocked: false,
                    amount: 0,
                    redeemPoints: 0,
                    applicableCashback: 0,
                    userId: "",
                    cardId: ""
                });
            }
            if (cardNumber.length === 10 && !isNaN(cardNumber)) {
                try {
                    const { data } = await fetchCardBalanceByCard(cardNumber);
                    if (data && data?.card) {
                        setCardDetails({
                            cardNumber: data?.card?.cardNumber,
                            isBlocked: data?.card?.isBlocked,
                            amount: data?.card?.amount,
                            redeemPoints: data?.card?.redeemPoints,
                            applicableCashback: data?.card?.applicableCashback,
                            userId: data?.card?.userId,
                            cardId: data?.card?._id
                        });
                    } else {
                        throw new Error("No card details found");
                    }
                } catch (error) {
                    console.log("Error fetching card details", error);
                    setCardDetails({
                        cardNumber: "",
                        isBlocked: false,
                        amount: 0,
                        redeemPoints: 0,
                        applicableCashback: 0,
                        userId: "",
                        cardId: ""
                    })
                }
            } else if (paymentMethod === "card" && cardNumber.length !== 10) {
                setCardDetails({
                    cardNumber: "",
                    isBlocked: false,
                    amount: 0,
                    redeemPoints: 0,
                    applicableCashback: 0,
                    userId: "",
                    cardId: ""
                });
            }

            if (paymentMethod === "cash" && cashPhone.length !== 10) {
                setCardDetails({
                    cardNumber: "",
                    isBlocked: false,
                    amount: 0,
                    redeemPoints: 0,
                    applicableCashback: 0,
                    userId: "",
                    cardId: ""
                });
            }
        };

        fetchCardDetails();
    }, [phone, fetchCardBalanceByPhone, cardNumber, fetchCardBalanceByCard, paymentMethod, cashPhone]);

    const handleOtpChange = (otp) => {
        setOtp(otp);
        if (otp.length === 6) {
            setDisablePay(prev => ({
                ...prev,
                mobile: false
            }));
        } else {
            setDisablePay(prev => ({
                ...prev,
                mobile: true
            }));
        }
    };

    const handleSendOtp = async () => {
        try {
            const newPhone = "+91" + phone;
            await generateOtp({ phone: newPhone });
            setShowOtpInput(true);
        } catch (error) {
            console.log(error);
            setOtpError("Error generating OTP");
        }
    };

    const handlePlaceOrderViaMobile = async () => {
        const totalAmount = Object.keys(cartItems).reduce((total, itemId) => {
            const item = cartItems[itemId];
            const quantity = Number(item.quantity) || 0;
            const price = Number(item.item.price) || 0;
            return total + quantity * price;
        }, 0);
        try {
            const res = await payWithMobile({ phone, otp, amount: totalAmount });
            console.log("Payment response", res);
            await handleCreateOrder(totalAmount);
            setOrderReset(true);
            setDisablePay(prev => ({
                ...prev,
                mobile: true
            }));
        } catch (error) {
            console.log(error);
            setOtpError("Error placing order");
        }
    };

    const handlePlaceOrderViaCash = async () => {
        const totalAmount = Object.keys(cartItems).reduce((total, itemId) => {
            const item = cartItems[itemId];
            const quantity = Number(item.quantity) || 0;
            const price = Number(item.item.price) || 0;
            return total + quantity * price;
        }, 0);
        try {
            const res = await payWithCash({ phone: cashPhone, amount: totalAmount });
            console.log("Payment response", res);
            await handleCreateOrder(totalAmount);
            setOrderReset(true);
        } catch (error) {
            console.log(error);
            setOtpError("Error placing order");
        }
    };

    const handleCashPhoneChange = (e) => {
        const value = e.target.value;
        setCashPhone(value);
        setCardNumber("");
        setPhone("");
        if (value.length === 10 && !isNaN(value)) {
            setDisablePay(prev => ({
                ...prev,
                cash: false
            }));
        } else {
            setDisablePay(prev => ({
                ...prev,
                cash: true
            }));
        }
    };

    const handleCardNumberChange = (e) => {
        const value = e.target.value;
        setCardNumber(value);
        setPhone("");
        setCashPhone("");
        if (value.length === 10 && !isNaN(value)) {
            setDisablePay(prev => ({
                ...prev,
                card: false
            }));
        } else {
            setDisablePay(prev => ({
                ...prev,
                card: true
            }));
        }
    };

    const handlePlaceOrderViaCard = async () => {
        const totalAmount = Object.keys(cartItems).reduce((total, itemId) => {
            const item = cartItems[itemId];
            const quantity = Number(item.quantity) || 0;
            const price = Number(item.item.price) || 0;
            return total + quantity * price;
        }, 0);
        try {
            const res = await payWithCard({ cardNumber, amount: totalAmount, restaurantId: restaurantDetails?.user?.restaurantName });
            console.log("Payment response", res);
            await handleCreateOrder(totalAmount);
            setOrderReset(true);
            setDisablePay(prev => ({
                ...prev,
                card: true
            }));
        } catch (error) {
            console.log(error);
            setOtpError("Error placing order");
        }
    }

    const [printBill, setPrintBill] = useState({
        restaurantName: "",
        restaurantMobile: "",
        restaurantEmail: "",
        orderId: "",
        orderDate: "",
        orderTime: "",
        name: "",
        items: [],
        totalAmount: 0,
        discount: 0,
        paymentMethod: "",
        paymentStatus: 0,
    });
    const [createOrder, { isLoading: isOrderLoading, isSuccess: isOrderSuccess, error: orderError }] = useCreateOrderMutation();

    const handleCreateOrder = async (totalAmount) => {
        const orderData = {
            restaurantId: restaurantDetails?.user?._id,
            items: Object.keys(cartItems)?.map(itemId => ({
                itemId: itemId,
                quantity: cartItems[itemId].quantity,
                price: cartItems[itemId].item.price
            })),
            phoneNumber: paymentMethod === "mobile" ? phone : paymentMethod === "cash" ? cashPhone : cardDetails.userId,
            totalAmount: totalAmount,
            status: "placed",
            paymentMethod: paymentMethod,
            discount: 0,
        };

        try {
            const res = await createOrder(orderData).unwrap();
            console.log("Order created successfully", res);

            const billData = {
                restaurantName: restaurantDetails?.user?.restaurantName,
                restaurantMobile: restaurantDetails?.user?.phoneNumber,
                restaurantEmail: restaurantDetails?.user?.email,
                orderId: res?.order?._id,
                orderDate: res?.order?.createdAt,
                orderTime: res?.order?.createdAt,
                name: res?.order?.name,
                items: cartItems,
                totalAmount: res?.order?.totalAmount,
                discount: res?.order?.discount,
                paymentMethod: res?.order?.paymentMethod,
                paymentStatus: res?.order?.paymentStatus
            };
            console.log("Bill data", billData);

            setPrintBill(billData);
        }
        catch (error) {
            console.log("Error creating order", error);
        }
    };

    useEffect(() => {
        const totalAmount = Object.keys(cartItems).reduce((total, itemId) => total + cartItems[itemId].quantity * cartItems[itemId].item.price, 0);
        if (cardDetails.amount + cardDetails.redeemPoints < totalAmount) {
            setDisablePay(prev => ({
                ...prev,
                card: true,
                mobile: true
            }));
        }
        setTotalOrderAmount(totalAmount);
    }, [cardDetails.amount, cardDetails.redeemPoints, cartItems])

    const handleOnClose = () => {
        if ((isCardPaymentSuccess || isMobilePaymentSuccess || isCashPaymentSuccess) && isOrderSuccess) {
            setPhone("");
            setCardNumber("");
            setCashPhone("");
            setOtp("");
            setOtpError("");
            setSelectedPaymentMethod("card");
            setShowOtpInput(false);
            setShowOtpbtn(false);
            setPrintBill({});
            setOrderReset(false);
            setDisablePay({
                card: true,
                mobile: true,
                cash: true
            });
        }
        onClose();
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 min-w-96">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Payment Options</h2>
                    <button className="text-black font-bold hover:text-gray-800" onClick={handleOnClose}>
                        X
                    </button>
                </div>
                <hr />
                <div className="flex flex-col gap-2">
                    <p className="text-xl font-medium mt-2">Total Amount: ₹ {Object.keys(cartItems).reduce((total, itemId) => total + cartItems[itemId].quantity * cartItems[itemId].item.price, 0)}</p>
                    <p>Select a payment method:</p>
                    <div className="flex gap-2 mb-8">
                        <button className={`border-2 border-blue-500 px-4 py-1 rounded-md ${paymentMethod === "card" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => setSelectedPaymentMethod("card")}>Fulltoss Card</button>
                        <button className={`border-2 border-blue-500 px-4 py-1 rounded-md ${paymentMethod === "mobile" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => setSelectedPaymentMethod("mobile")}>Fulltoss Mobile Wallet</button>
                        <button className={`border-2 border-blue-500 px-4 py-1 rounded-md ${paymentMethod === "cash" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => setSelectedPaymentMethod("cash")}>Cash</button>
                        <button className={`border-2 border-blue-500 px-4 py-1 rounded-md ${paymentMethod === "upi" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => setSelectedPaymentMethod("upi")}>UPI</button>
                    </div>

                    {
                        paymentMethod === "upi" &&
                        <div className="text-center">
                            <p>UPI Payment is not available yet.</p>
                        </div>
                    }
                    {
                        paymentMethod === "card" &&
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between gap-2">
                                <input
                                    type="text"
                                    className="w-72 p-2 border border-gray-300 rounded-md outline-none"
                                    placeholder="Card Number"
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                    disabled={isCardPaymentLoading || isOrderLoading || orderReset}
                                />
                            </div>
                            {isCardBalanceCardLoading && <p>Loading card details...</p>}
                            {!isCardBalanceCardLoading && isCardBalanceCardSuccess && cardDetails.cardNumber && <div className="flex flex-col gap-1">
                                <p>Balance: ₹ {cardDetails?.amount}</p>
                                <p>Past Reward Points: {cardDetails?.redeemPoints}</p>
                                <p>Past Chips: {cardDetails?.applicableCashback}</p>
                                <p>Updated Chips will be: ₹ ({cardDetails?.applicableCashback} + {(totalOrderAmount - cardDetails?.redeemPoints) > 0 ? totalOrderAmount - cardDetails?.redeemPoints : 0}) = {cardDetails?.applicableCashback + ((totalOrderAmount - cardDetails?.redeemPoints) > 0 ? (totalOrderAmount - cardDetails?.redeemPoints) : 0)}</p>
                                <hr className="my-2" />
                                <p className="flex justify-between">Reward Points deducted: <span className="font-medium">₹ {totalOrderAmount > cardDetails?.redeemPoints ? cardDetails?.redeemPoints : totalOrderAmount}</span></p>
                                <p className="flex justify-between">Net balance to be deducted: <span className="font-medium">₹ {(totalOrderAmount - cardDetails?.redeemPoints) > 0 ? totalOrderAmount - cardDetails?.redeemPoints : 0}</span></p>
                                <hr className="my-2" />
                                <p className="flex justify-between">Total amount to be paid: <span className="font-medium">₹ {totalOrderAmount}</span></p>
                            </div>
                            }
                            <button className={`bg-blue-500 text-white px-4 py-2 rounded-md ${disablePay.card || isCardPaymentLoading || isCardBalanceCardLoading || isCardPaymentSuccess || isOrderLoading ? "opacity-50 cursor-not-allowed" : ""}`} disabled={disablePay.card || isCardPaymentLoading || isCardPaymentSuccess || isOrderLoading} onClick={handlePlaceOrderViaCard}>
                                {isCardPaymentLoading || isOrderLoading ? "Processing..." : "Pay via Card"}
                                {
                                    console.log(disablePay.card, isCardPaymentLoading, isCardPaymentSuccess, isOrderLoading, isOrderSuccess)
                                }
                            </button>
                            {isCardPaymentSuccess && orderReset && <p className="text-green-500">Payment successful</p>}
                            {isOrderSuccess && orderReset && <p className="text-green-500">Order placed successfully</p>}
                            {cardPaymentError && <p className="text-red-500">{cardPaymentError.data.message}</p>}
                            {cardBalanceCardError && <p className="text-red-500">{cardBalanceCardError.data.message}</p>}
                            {orderError && <p className="text-red-500">{orderError.data.message}</p>}
                        </div>
                    }
                    {
                        paymentMethod === "mobile" &&
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between gap-2">
                                <input
                                    type="number"
                                    className="w-72 p-2 border border-gray-300 rounded-md outline-none"
                                    placeholder="Enter 10 digit phone number"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    disabled={showOtpInput}
                                />
                                {showOtpbtn && !showOtpInput && isCardBalancePhoneSuccess && (
                                    <button
                                        className="bg-blue-500 text-white px-4 py-1 rounded-md"
                                        disabled={!showOtpbtn}
                                        onClick={handleSendOtp}>
                                        {isOtpLoading ? "Sending OTP..." : "Send OTP"}
                                    </button>
                                )}
                            </div>
                            {showOtpInput && (
                                <OTPInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={6}
                                    shouldAutoFocus={true}
                                    renderInput={(props) => <input {...props} disabled={isMobilePaymentSuccess || isMobilePaymentLoading || isOrderLoading} />}
                                    inputStyle={{ width: '36px', height: '36px', border: '1px solid #ccc', borderRadius: '5px', margin: '0 2px' }}
                                />
                            )}
                            {isCardBalancePhoneLoading && <p>Loading card details...</p>}
                            {!isCardBalancePhoneLoading && isCardBalancePhoneSuccess && cardDetails?.userId && <div className="flex flex-col gap-1">
                                <p>Balance: ₹ {cardDetails?.amount}</p>
                                <p>Past Reward Points: {cardDetails?.redeemPoints}</p>
                                <p>Past Coins: {cardDetails?.applicableCashback}</p>
                                <p>Cashback Applicable: ₹ ({cardDetails?.applicableCashback} + {Object.keys(cartItems).reduce((total, itemId) => total + cartItems[itemId].quantity * cartItems[itemId].item.price, 0) - cardDetails?.redeemPoints}) = {cardDetails?.applicableCashback + Object.keys(cartItems).reduce((total, itemId) => total + cartItems[itemId].quantity * cartItems[itemId].item.price, 0) - cardDetails?.redeemPoints}</p>
                            </div>
                            }
                            {otpError && <p className="text-red-500">{otpError}</p>}
                            <button className={`bg-blue-500 text-white px-4 py-2 rounded-md ${disablePay.mobile || isMobilePaymentLoading || isMobilePaymentSuccess || isOrderLoading ? "opacity-50 cursor-not-allowed" : ""}`} disabled={disablePay.mobile || isMobilePaymentLoading || isMobilePaymentSuccess || isOrderLoading} onClick={handlePlaceOrderViaMobile}>
                                {isMobilePaymentLoading || isOrderLoading ? "Processing..." : "Pay via Mobile"}
                            </button>
                            {isMobilePaymentSuccess && orderReset && <p className="text-green-500">Payment successful</p>}
                            {isOrderSuccess && orderReset && <p className="text-green-500">Order placed successfully</p>}
                            {mobilePaymentError && <p className="text-red-500">{mobilePaymentError.data.message}</p>}
                            {cardBalancePhoneError && <p className="text-red-500">{cardBalancePhoneError.data.message}</p>}
                            {orderError && <p className="text-red-500">{orderError.data.message}</p>}
                        </div>
                    }
                    {
                        cardDetails?.userId && cardDetails?.amount + cardDetails?.redeemPoints < Object.keys(cartItems).reduce((total, itemId) => total + cartItems[itemId].quantity * cartItems[itemId].item.price, 0) - cardDetails?.redeemPoints &&
                        <div className="text-left">
                            <p className="text-red-500">Low card balance</p>
                        </div>
                    }
                    {
                        paymentMethod === "cash" &&
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between gap-2">
                                <input type="text" className="w-72 p-2 border border-gray-300 rounded-md outline-none" placeholder="Mobile Number" value={cashPhone} onChange={handleCashPhoneChange} />
                            </div>
                            <button className={`bg-blue-500 text-white px-4 py-2 rounded-md ${disablePay.cash || isCashPaymentLoading || isCashPaymentSuccess || isOrderLoading ? "opacity-50 cursor-not-allowed" : ""}`} disabled={disablePay.cash || isCashPaymentLoading || isCashPaymentSuccess || isOrderLoading} onClick={handlePlaceOrderViaCash}>
                                {isCashPaymentLoading || isOrderLoading ? "Processing..." : "Pay via Cash"}
                            </button>
                            {isCashPaymentSuccess && orderReset && <p className="text-green-500">Payment successful</p>}
                            {isOrderSuccess && orderReset && <p className="text-green-500">Order placed successfully</p>}
                            {cashPaymentError && <p className="text-red-500">{cashPaymentError.data.message}</p>}
                            {orderError && <p className="text-red-500">{orderError.data.message}</p>}
                        </div>
                    }
                </div>

                {
                    printBill.restaurantName && (
                        <div className="mt-6 text-center">
                            <PDFDownloadLink
                                document={<PDFBill billData={printBill} />}
                                fileName={`Order_${printBill.orderId}.pdf`}
                            >
                                {({ loading }) => (
                                    <button className="bg-green-500 text-white px-4 py-2 rounded-md">
                                        {loading ? "Generating Bill..." : "Download Bill"}
                                    </button>
                                )}
                            </PDFDownloadLink>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PaymentModal;
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ItemCard from "../components/ItemCard";
import PaymentModal from "../components/PaymentModal";
import { useState, useEffect } from "react";
import { useGetAllItemsOfUserQuery } from "../redux/services/itemService";
import { useGetUserDetailsQuery } from "../redux/services/userService";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addItemToCart, removeItemFromCart, clearCart, updateItemQuantity } from "../redux/slices/cartSlice";
import Cookies from "js-cookie";
import { setUser } from "../redux/slices/userSlice";
import { clearPaymentStatus } from "../redux/slices/paymentSlice";
import { clearOrderStatus } from "../redux/slices/orderSlice";
import { clearOtpStatus } from "../redux/slices/otpSlice";
import Loader from "../components/Loader";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cart.items);
    const restaurantUser = useSelector(state => state.user.user);
    const restaurantId = Cookies.get("restaurantId");
    const [filteredItems, setFilteredItems] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState("Sandwich");
    const { data, isItemsLoading, error } = useGetAllItemsOfUserQuery(restaurantId);
    const { data: restaurantDetails, isLoading: restaurantDetailsLoading, error: restaurantDetailsError } = useGetUserDetailsQuery(restaurantId);
    const uniqueCategories = data?.items ? [...new Set(data.items.map(item => item.category))] : [];

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("card");

    useEffect(() => {
        if (restaurantDetails && restaurantDetails.user) {
            dispatch(setUser(restaurantDetails.user));
        }
    }, [restaurantDetails, dispatch]);

    useEffect(() => {
        setFilteredItems(data?.items?.filter(item => item.category === selectedCategory));
    }, [selectedCategory, data?.items]);

    const handleAddToCart = (item) => {
        dispatch(addItemToCart({ item }));
        dispatch(clearPaymentStatus());
        dispatch(clearOrderStatus());
        dispatch(clearOtpStatus());
    }

    const handleRemoveFromCart = (itemId) => {
        dispatch(removeItemFromCart({ itemId }));
        dispatch(clearPaymentStatus());
        dispatch(clearOrderStatus());
        dispatch(clearOtpStatus());
    }

    const handleQuantityChange = (itemId, newQuantity) => {
        dispatch(updateItemQuantity({ itemId, quantity: newQuantity }));
        dispatch(clearPaymentStatus());
        dispatch(clearOrderStatus());
        dispatch(clearOtpStatus());
    }

    if (restaurantDetailsLoading) return <Loader />;
    if (error || restaurantDetailsError) return <div>
        <Navbar />
        <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
            <p className="text-2xl font-semibold m-0 p-0">Please <Link to="/login" className="text-blue-500">login</Link> to continue</p>
        </div>
    </div>;

    return (
        <div className="flex flex-col">
            {
                restaurantDetailsLoading ? <Loader /> : (
                    <Navbar />
                )
            }
            {
                isItemsLoading ? <Loader /> :
                    restaurantUser?.isVerified ? (
                        <div className="flex">
                            <Sidebar categories={uniqueCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                            <div className="flex w-full">
                                <div className="w-1/2 bg-gray-200 p-4 grid grid-cols-3 gap-3 border-r-4 border-gray-300">
                                    {filteredItems?.map((item, index) => (
                                        <ItemCard key={index} item={item} isInCart={cartItems[item._id]?.quantity > 0} onAddToCart={handleAddToCart} />
                                    ))}
                                </div>
                                <div className="w-1/2 h-[calc(100vh-4rem)] bg-white flex flex-col">
                                    <div className="overflow-auto flex-grow">
                                        <table className="w-full min-w-max table-auto text-left">
                                            <thead className="sticky top-0 shadow-md z-10">
                                                <tr>
                                                    <th className="border-b border-blue-gray-100 bg-gray-100 py-3 px-2">S.No.</th>
                                                    <th className="border-b border-blue-gray-100 bg-gray-100 py-3 px-2">Items</th>
                                                    <th className="border-b border-blue-gray-100 bg-gray-100 py-3 px-2">Quantity</th>
                                                    <th className="border-b border-blue-gray-100 bg-gray-100 py-3 px-2">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(cartItems).length === 0 ? (
                                                        <tr>
                                                            <td colSpan="4" className="py-3 px-2 text-center">No Items in Cart</td>
                                                        </tr>
                                                    ) : (
                                                        Object.keys(cartItems).map((itemId, index) => (
                                                            <tr key={index}>
                                                                <td className="py-3 px-2 border-b border-blue-gray-50">{index + 1}</td>
                                                                <td className="py-3 px-2 border-b border-blue-gray-50">{cartItems[itemId].item.name}</td>
                                                                <td className="py-3 px-2 border-b border-blue-gray-50">
                                                                    <div className="flex items-center h-6 bg-gray-100 border border-gray-300 w-fit rounded-sm overflow-hidden">
                                                                        <button className="w-5 flex justify-center items-center text-center" onClick={() => handleQuantityChange(itemId, cartItems[itemId].quantity - 1)}><span>-</span></button>
                                                                        <input type="number" className="w-6 flex justify-center items-center text-center text-sm" value={cartItems[itemId].quantity} disabled />
                                                                        <button className="w-5 flex justify-center items-center text-center" onClick={() => handleQuantityChange(itemId, cartItems[itemId].quantity + 1)}><span>+</span></button>
                                                                    </div>
                                                                    <button className="text-red-500 px-2 text-sm rounded-md" onClick={() => handleRemoveFromCart(itemId)}>Remove</button>
                                                                </td>
                                                                <td className="py-3 px-2 border-b border-blue-gray-50">
                                                                    <div className="flex flex-col items-center gap-1">
                                                                        <span className="text-sm font-medium">₹{cartItems[itemId].item.price}</span>
                                                                        <span className="text-xs">{cartItems[itemId].quantity}x{cartItems[itemId].item.price} = ₹{cartItems[itemId].quantity * cartItems[itemId].item.price}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        Object.keys(cartItems).length > 0 && (
                                            <div className="flex justify-end p-2">
                                                <button className="bg-red-500 text-white px-3 py-1 rounded-md" onClick={() => dispatch(clearCart())}>Remove All</button>
                                            </div>
                                        )
                                    }
                                    <div className="bg-gray-200 p-4 border-t-2 border-gray-300">
                                        <div className="flex justify-between">
                                            <span className="font-bold">Total:</span>
                                            <span className="font-bold">₹ {Object.keys(cartItems).reduce((total, itemId) => total + cartItems[itemId].quantity * cartItems[itemId].item.price, 0)}</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-end p-3">
                                        {/* <p className="mr-2">Pay via:</p> */}
                                        <div className="flex gap-2">
                                            <button className={`bg-blue-500 text-white px-4 py-1 rounded-md ${Object.keys(cartItems).length === 0 ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => { navigate("/payment"); dispatch(clearPaymentStatus()); dispatch(clearOrderStatus()); }}>Confirm Order</button>
                                            <div className="hidden">
                                                <button className={`bg-blue-500 text-white px-4 py-1 rounded-md ${Object.keys(cartItems).length === 0 ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => { setIsPaymentModalOpen(true); setSelectedPaymentMethod("card"); }} disabled={Object.keys(cartItems).length === 0}>Fulltoss Card</button>
                                                <button className={`bg-blue-500 text-white px-4 py-1 rounded-md ${Object.keys(cartItems).length === 0 ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => { setIsPaymentModalOpen(true); setSelectedPaymentMethod("mobile"); }} disabled={Object.keys(cartItems).length === 0}>Fulltoss Mobile Wallet</button>
                                                <button className={`bg-blue-500 text-white px-4 py-1 rounded-md ${Object.keys(cartItems).length === 0 ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => { setIsPaymentModalOpen(true); setSelectedPaymentMethod("cash"); }} disabled={Object.keys(cartItems).length === 0}>Cash</button>
                                                <button className={`bg-blue-500 text-white px-4 py-1 rounded-md ${Object.keys(cartItems).length === 0 ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => { setIsPaymentModalOpen(true); setSelectedPaymentMethod("upi"); }} disabled={Object.keys(cartItems).length === 0}>UPI</button>
                                            </div>
                                        </div>
                                        <PaymentModal isOpen={isPaymentModalOpen} onClose={() => setIsPaymentModalOpen(false)} paymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} cartItems={cartItems} restaurantDetails={restaurantUser} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
                            <p className="text-2xl font-semibold m-0 p-0">Please verify your restaurant to continue</p>
                        </div>
                    )
            }
        </div>
    )
}

export default Home;

import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const Dashboard = () => {
    return (
        <div>
            <Navbar />
            <div className="flex flex-col gap-4 justify-center items-center h-[calc(100vh-4rem)]">
                <p className="text-2xl font-semibold m-0 p-0">Welcome to the dashboard</p>
                <Link to="/add-item" className="bg-blue-500 text-white px-4 py-2 rounded-md">Add Item</Link>
                <Link to="/players" className="bg-blue-500 text-white px-4 py-2 rounded-md">Players</Link>
                <Link to="/predictions" className="bg-blue-500 text-white px-4 py-2 rounded-md">Predictions</Link>
                <Link to="/questions" className="bg-blue-500 text-white px-4 py-2 rounded-md">Questions</Link>
                <Link to="/master-table" className="bg-blue-500 text-white px-4 py-2 rounded-md">Master Table</Link>
            </div>
        </div>
    )
}

export default Dashboard;

const ItemCard = ({item, isInCart, onAddToCart}) => {
    return ( 
        <div className={`flex flex-col justify-between h-28 bg-white shadow-md p-2 border-l-4 ${item.type === "veg" ? "border-green-500" : "border-red-500"}`}>
            {/* veg-green, non-veg-red, !item?.isAvailable-gray */}
            <h1 className={`text-lg font-semibold ${!item?.isAvailable ? "text-gray-500" : item.type === "veg" ? "text-green-500" : "text-red-500"}`}>{item.name}</h1>
            <div className="flex justify-between items-center">
                <div className={`flex items-center gap-0.5 ${!item?.isAvailable ? "text-gray-500" : ""}`}>
                    <span className="font-semibold">₹</span>
                    <span className="font-semibold">{item.price}</span>
                </div>
                {item?.isAvailable ?
                    <button className={`text-white px-2 py-0.5 rounded-md text-sm ${isInCart || !item?.isAvailable ? "bg-gray-500" : "bg-blue-500"}`} disabled={isInCart || !item?.isAvailable} onClick={() => onAddToCart(item)}>{isInCart ? "Added" : "Add"}</button>
                    :
                    <p className="text-gray-500 text-sm">Not Available</p>
                }
            </div>
        </div>
    )
}

export default ItemCard;
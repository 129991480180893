import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import { useGetAllPredictionsQuery } from "../redux/services/playerService";
import { useGetAllQuestionsQuery } from "../redux/services/playerService";
import { useGetAllPlayersQuery } from "../redux/services/playerService";

const MasterPage = () => {
    const { data: predictions, isLoading: isLoadingPredictions, isError: isErrorPredictions } = useGetAllPredictionsQuery();
    const { data: questions, isLoading: isLoadingQuestions, isError: isErrorQuestions } = useGetAllQuestionsQuery();
    const { data: playerData } = useGetAllPlayersQuery();

    const [masterData, setMasterData] = useState([]);

    useEffect(() => {
        if (predictions && questions && playerData) {
            // Create a map of questionId to question text for easy lookup
            const questionMap = questions.reduce((acc, question) => {
                acc[question._id] = question.question;
                return acc;
            }, {});

            // Create a map of playerId to playerName for efficient lookup
            const playerMap = playerData.reduce((acc, player) => {
                acc[player._id] = player.name;
                return acc;
            }, {});

            // Combine predictions with question text based on questionId
            const combinedData = predictions.map((prediction) => {
                const questionText = questionMap[prediction.questionId] || "Unknown Question";

                // Find the question based on questionId
                const question = questions.find((question) => question._id === prediction.questionId);
                const options = question?.options || [];
                let updatedOptions = options;

                // If the question's optionType is "player" or "team", map the options to player names
                if (question && (question.optionType === "player" || question.optionType === "team")) {
                    updatedOptions = options.map((option) => playerMap[option] || "Unknown Player");
                }

                // Return combined data with options transformed if applicable
                return { ...prediction, questionText, options: updatedOptions };
            });

            setMasterData(combinedData.reverse());
        }
    }, [predictions, questions, playerData]);

    if (isLoadingPredictions || isLoadingQuestions) return <Loader />;
    if (isErrorPredictions || isErrorQuestions) return <div>Error fetching data</div>;

    return (
        <div>
            <Navbar />
            <table className="mx-auto">
                <thead>
                    <tr className="border-b border-gray-200">
                        <th className="text-left p-3 px-5">S.No</th>
                        <th className="text-left p-3 px-5">Question</th>
                        <th className="text-left p-3 px-5">Options</th>
                        <th className="text-left p-3 px-5">Customer Option</th>
                        <th className="text-left p-3 px-5">User Id</th>
                        <th className="text-left p-3 px-5">Chips</th>
                        <th className="text-left p-3 px-5">Is Deducted</th>
                        <th className="text-left p-3 px-5">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {masterData.map((prediction, index) => (
                        <tr key={prediction._id} className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="p-3 px-5">{index + 1}</td>
                            <td className="p-3 px-5 min-w-[200px]">{prediction.questionText}</td>
                            <td className="p-3 px-5">{prediction.options.map((opt, ind) => (
                                <p key={ind}>{ind}. {opt}</p>
                            ))}</td>
                            <td className="p-3 px-5">{prediction.option.join(", ")}</td>
                            <td className="p-3 px-5">{prediction.userId}</td>
                            <td className="p-3 px-5">{prediction.chips}</td>
                            <td className="p-3 px-5">{prediction.isDeducted ? "Yes" : "No"}</td>
                            <td className="p-3 px-5">{new Date(prediction.createdAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MasterPage;

import React from "react";
import { useSelector } from "react-redux";

const OrderBill = React.forwardRef((props, ref) => {
    const order = useSelector(state => state.order?.order?.order);
    const restaurantDetails = useSelector(state => state.user.user);
    console.log("order", order);
    return (
        <div ref={ref} className="bill flex flex-col gap-4">
            <div className="max-w-md min-w-[320px] mx-auto p-4 border border-gray-300 rounded shadow-lg">
                <h1 className="text-xl font-bold text-center">{restaurantDetails?.restaurantName}</h1>
                <p className="text-center font-medium">{restaurantDetails?.address}</p>
                <p className="text-center">+91 {restaurantDetails?.phoneNumber}</p>
                <p className="text-center">{restaurantDetails?.email}</p>
                <p className="text-center mt-2 font-medium">Token No: {order?.token}</p>
                <hr className="my-2 border-b-[1px] border-b-black" />
                <div>
                    <p className="flex justify-between">Name: <span className="font-medium">{order?.name}</span></p>
                    <p className="flex justify-between">Date: <span className="font-medium">{new Date(order?.createdAt).toLocaleDateString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}, {new Date(order?.createdAt).toLocaleTimeString("en-IN", { hour: '2-digit', minute: '2-digit' })}</span></p>
                    <p className="flex justify-between">Table Number: <span className="font-medium">{order?.tableNumber}</span></p>
                    <p className="flex justify-between">Mobile No: <span className="font-medium">{order?.phoneNumber}</span></p>
                </div>
                <hr className="my-2 border-b-[1px] border-b-black" />
                <table className="w-full text-left">
                    <thead className="border-b-[1px] border-b-black">
                        <tr>
                            <th className="font-medium">Item Name</th>
                            <th className="font-medium">Qty</th>
                            <th className="font-medium">Rate</th>
                            <th className="font-medium">Amt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order?.items?.map((item, index) => (
                                <tr key={index}>
                                    <td className="py-1">{item?.itemName}</td>
                                    <td className="py-1">{item?.quantity}</td>
                                    <td className="py-1">{item?.price}</td>
                                    <td className="py-1">{item?.price * item?.quantity}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <hr className="my-2 border-b-[1px] border-b-black" />
                <div className="flex justify-between">
                    <p>Total Qty: <span className="font-medium">{order?.items?.reduce((acc, item) => acc + item?.quantity, 0)}</span></p>
                    <p>Subtotal: <span className="font-medium">₹ {order?.items?.reduce((acc, item) => acc + item?.price * item?.quantity, 0)}</span></p>
                </div>
                <hr className="my-4" />
                <h2 className="flex justify-between font-medium">Total Amount: <span className="font-medium">₹ {order?.totalAmount}</span></h2>
                <h2 className="flex justify-between font-medium">Discount (Rewards): <span className="font-medium">- ₹ {order?.discount}</span></h2>
                <hr className="my-2" />
                <h2 className="flex justify-between font-medium">Amount Paid: <span className="font-medium">₹ {order?.paymentAmount}</span></h2>
                <p className="flex justify-between text-xs">Paid by: <span className="font-medium">{order?.paymentMethod}</span></p>
                <hr className="my-4" />
                <div className="flex justify-between">
                    <p>Rewards Rem: <span className="font-medium">₹ {order?.points}</span></p>
                    <p>Total Chips: <span className="font-medium">{order?.chips}</span></p>
                </div>
                <hr className="my-4" />
                <div className="flex flex-col items-center gap-2">
                    <p className="font-medium">Scan QR Code for Prediction</p>
                    <img src="./qrcode.png" alt="QR Code" className="w-24 h-24 mx-auto" />
                    <p>Printed On: {new Date().toLocaleDateString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}, {new Date().toLocaleTimeString("en-IN", { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <p className="text-center text-sm mt-2">Powered By: www.fulltoss.club</p>
            </div>

            <div className="max-w-md min-w-[320px] mx-auto p-4 border border-gray-300 rounded shadow-lg">
                <p className="text-center text-lg font-bold">Kitchen</p>
                <p className="text-center text-xl mt-2 font-bold">Token No: {order?.token}</p>
                <hr className="my-2 border-b-[1px] border-b-black" />
                <div>
                    <p className="flex justify-between">Name: <span className="font-medium">{order?.name}</span></p>
                    <p className="flex justify-between">Table Number: <span className="font-medium">{order?.tableNumber}</span></p>
                </div>
                <hr className="my-2 border-b-[1px] border-b-black" />
                <table className="w-full text-left">
                    <thead className="border-b-[1px] border-b-black">
                        <tr>
                            <th className="font-medium">Item Name</th>
                            <th className="font-medium">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order?.items?.map((item, index) => (
                                <tr key={index}>
                                    <td className="py-1">{item?.itemName}</td>
                                    <td className="py-1">{item?.quantity}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <hr className="my-2 border-b-[1px] border-b-black" />
                <div className="flex justify-between">
                    <p>Total Items: <span className="font-medium">{order?.items?.length}</span></p>
                    <p>Total Qty: <span className="font-medium">{order?.items?.reduce((acc, item) => acc + item?.quantity, 0)}</span></p>
                </div>
                <hr className="my-2" />
                <p className="text-center text-sm mt-2">Powered By: www.fulltoss.club</p>
            </div>
        </div>
    )
});

export default OrderBill;

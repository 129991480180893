import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("token");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAllUsers: builder.query({
            query: () => "/users",
        }),
        registerRestaurant: builder.mutation({
            query: (data) => ({
                url: "/register",
                method: "POST",
                body: data
            })
        }),
        loginRestaurant: builder.mutation({
            query: (data) => ({
                url: "/login",
                method: "POST",
                body: data
            })
        }),
        getUserDetails: builder.query({
            query: (id) => `/user/${id}`
        })
    })
})

export const { useGetAllUsersQuery, useRegisterRestaurantMutation, useLoginRestaurantMutation, useGetUserDetailsQuery } = userApi;
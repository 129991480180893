
const Sidebar = ({categories, selectedCategory, setSelectedCategory}) => {
    return (
        <div className="w-60 h-[calc(100vh-4rem)] overflow-y-scroll flex flex-col gap-0.5 p-0.5 bg-gray-100 border-r-2 border-gray-200">
            {
                categories?.map((category, index) => (
                    <div key={index} className={`card p-2 flex items-center justify-center min-h-20 bg-black text-white ${selectedCategory === category ? "bg-red-600 text-white" : ""}`} onClick={() => setSelectedCategory(category)}>
                        {index + 1}. {category}
                    </div>
                ))
            }
        </div>
    )
}

export default Sidebar;


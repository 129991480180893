import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../redux/slices/userSlice";

const Navbar = () => {
    const navigate = useNavigate();
    const token = Cookies.get("token");
    const dispatch = useDispatch();
    const restaurantUser = useSelector(state => state.user.user);

    const handleLogout = () => {
        Cookies.remove("token");
        Cookies.remove("restaurantId");
        dispatch(clearUser());
        navigate("/login");
    }

    return (
        <div className="flex justify-between items-center px-4 py-1 h-16 bg-blue-600 text-white w-full">
            <Link to="/" className="text-2xl font-semibold p-0 m-0">{restaurantUser ? restaurantUser?.restaurantName : "FullToss POS System"}</Link>
            {!token ? (
                <div className="flex gap-4">
                    <Link to="/register" className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold">Register</Link>
                    <Link to="/login" className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold">Login</Link>
                </div>
            ) : <div className="flex gap-4">
                <Link to="/dashboard" className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold">Dashboard</Link>
                <button className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold" onClick={handleLogout}>Logout</button>
            </div>}
        </div>
    )
}

export default Navbar;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dev.fulltoss.shop/api/v1/";

export const playerApi = createApi({
    reducerPath: "playerApi",
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getAllPlayers: builder.query({
            query: () => "/players",
        }),
        getAllPredictions: builder.query({
            query: () => "/predictions",
        }),
        getAllQuestions: builder.query({
            query: () => "/questions",
        }),
        getPlayerById: builder.query({
            query: (id) => `/player/${id}`,
        }),
    }),
});

export const { useGetAllPlayersQuery, useGetAllPredictionsQuery, useGetAllQuestionsQuery, useGetPlayerByIdQuery } = playerApi;
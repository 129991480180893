import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useGetAllPredictionsQuery } from "../redux/services/playerService";

const Predictions = () => {
    const { data, isLoading, isError } = useGetAllPredictionsQuery();
    return <div>
        <Navbar />
        {isLoading && <Loader />}
        {isError && <div>Error fetching predictions</div>}

        <table className="max-w-[1000px] mx-auto">
            <thead>
                <tr className="border-b border-gray-200">
                    <th className="text-left p-3 px-5">S.No</th>
                    <th className="text-left p-3 px-5">Question Id</th>
                    <th className="text-left p-3 px-5">Selected Options</th>
                    <th className="text-left p-3 px-5">User Id</th>
                    <th className="text-left p-3 px-5">Chips</th>
                    <th className="text-left p-3 px-5">Is Deducted</th>
                    <th className="text-left p-3 px-5">Created At</th>
                </tr>
            </thead>
            {data && data.map((prediction, index) => (
                <tbody>
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                        <td className="p-3 px-5">{index + 1}</td>
                        <td className="p-3 px-5">{prediction.questionId}</td>
                        <td className="p-3 px-5">{prediction.option.map((option) => option).join(", ")}</td>
                        <td className="p-3 px-5">{prediction.userId}</td>
                        <td className="p-3 px-5">{prediction.chips}</td>
                        <td className="p-3 px-5">{prediction.isDeducted ? "Yes" : "No"}</td>
                        <td className="p-3 px-5">{new Date(prediction.createdAt).toLocaleString()}</td>
                    </tr>
                </tbody>
            ))}
        </table>
    </div>
}

export default Predictions;


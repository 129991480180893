import { createSlice } from "@reduxjs/toolkit";
import { cardApi } from "../services/cardService";

const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        updatedCard: null,
        isLoading: false,
        error: null,
        success: false
    },
    reducers: {
        clearPaymentStatus: (state) => {
            state.updatedCard = null;
            state.error = null;
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(cardApi.endpoints.payWithCard.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(cardApi.endpoints.payWithCard.matchFulfilled, (state, action) => {
            state.updatedCard = action.payload;
            state.isLoading = false;
            state.success = true;
        })
        builder.addMatcher(cardApi.endpoints.payWithCard.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
            state.success = false;
        })
        builder.addMatcher(cardApi.endpoints.payWithMobile.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(cardApi.endpoints.payWithMobile.matchFulfilled, (state, action) => {
            state.updatedCard = action.payload;
            state.isLoading = false;
            state.success = true;
        })
        builder.addMatcher(cardApi.endpoints.payWithMobile.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
            state.success = false;
        })
        builder.addMatcher(cardApi.endpoints.payWithCash.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(cardApi.endpoints.payWithCash.matchFulfilled, (state, action) => {
            state.updatedCard = action.payload;
            state.isLoading = false;
            state.success = true;
        })
        builder.addMatcher(cardApi.endpoints.payWithCash.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
            state.success = false;
        })
    }
})

export const { clearPaymentStatus } = paymentSlice.actions;
export default paymentSlice.reducer;

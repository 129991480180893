import { createSlice } from "@reduxjs/toolkit";
import { cardApi } from "../services/cardService";

const otpSlice = createSlice({
    name: "otp",
    initialState: {
        isLoading: false,
        error: null,
        message: null
    },
    reducers: {
        clearOtpStatus: (state) => {
            state.isLoading = false;
            state.error = null;
            state.message = null;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(cardApi.endpoints.generateOtp.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(cardApi.endpoints.generateOtp.matchFulfilled, (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
        })
        builder.addMatcher(cardApi.endpoints.generateOtp.matchRejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        })
    }
})

export const { clearOtpStatus } = otpSlice.actions;
export default otpSlice.reducer;    
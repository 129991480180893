"use client";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCreateNewCardMutation, useLazyGetCardBalanceByPhoneQuery, useUpdateCardMutation } from "../redux/services/cardService";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { clearCardStatus, clearCardError } from "../redux/slices/cardSlice";

const CreateNewUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [createNewCard, { isLoading, error }] = useCreateNewCardMutation();
    const [updateCard, { isLoading: isUpdateLoading, error: updateError }] = useUpdateCardMutation();
    const [getCardBalanceByPhone] = useLazyGetCardBalanceByPhoneQuery();
    const isCardBalanceLoading = useSelector(state => state.card.isLoading);
    const errorCardBalance = useSelector(state => state.card.error);
    const [isAdded, setIsAdded] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");

    const cardData = useSelector((state) => state.card.cardDetails);
    console.log(cardData?.card);
    const [formData, setFormData] = useState({
        cardNumber: "",
        name: "",
        isBlocked: false,
        amount: 0,
        redeemPoints: 0,
        applicableCashback: 0,
        userId: ""
    });

    useEffect(() => {
        if(phoneNumber.length === 10 && !isNaN(phoneNumber)) {
            getCardBalanceByPhone(phoneNumber);
        } else if(phoneNumber.length !== 10 || isNaN(phoneNumber)) {
            dispatch(clearCardStatus());
            dispatch(clearCardError());
            setIsAdded(false);
            setFormData({ ...formData, cardNumber: "", name: "", amount: 0, redeemPoints: 0, applicableCashback: 0, isBlocked: false });
        }
    }, [phoneNumber, getCardBalanceByPhone, dispatch]);

    useEffect(() => {
        if(errorCardBalance) {
            dispatch(clearCardError());
        }
        if(cardData?.card) {
            setIsAdded(true);
            setFormData({ ...formData, cardNumber: cardData?.card?.cardNumber, name: cardData?.card?.name, amount: cardData?.card?.amount, redeemPoints: cardData?.card?.redeemPoints, applicableCashback: cardData?.card?.applicableCashback, isBlocked: cardData?.card?.isBlocked });
        }
    }, [errorCardBalance, cardData, dispatch, isAdded]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if(name === "userId" && value.length === 10) {
            getCardBalanceByPhone(value);
        } else if(name === "userId" && value.length !== 10) {
            setIsAdded(false);
        }
    }

    const handleCreateNewUserSubmit = async (e) => {
        e.preventDefault();

        const token = Cookies.get("token");
        if(!token) {
            navigate("/login");
        }

        try{
            await createNewCard({...formData, userId: phoneNumber});

            setFormData({
                cardNumber: "",
                name: "",
                isBlocked: false,
                amount: 0,
                redeemPoints: 0,
                applicableCashback: 0,
                userId: ""
            });
            alert("Card created successfully");
        } catch (error) {
            alert("Error creating new card");
        }
    }

    const handleUpdateCardSubmit = async (e) => {
        e.preventDefault();

        if(phoneNumber.length !== 10 || isNaN(phoneNumber)) {
            return;
        }

        try {
            if(cardData?.card?._id) {
                await updateCard({ id: cardData?.card?._id, formData });

                setFormData({
                    cardNumber: "",
                    name: "",
                    isBlocked: false,
                    amount: 0,
                    redeemPoints: 0,
                    applicableCashback: 0,
                });

                setIsAdded(false);
            } else{
                alert("Card ID not found");
            }
        } catch (error) {
            alert("Error updating card");
        }
    }

    return <div>
        <Navbar />
        <div className="flex flex-col items-center justify-center p-4">
            <h1 className="text-2xl font-semibold mb-4">Create New User</h1>

            <div className="flex flex-col gap-4 max-w-md w-full">
                <div className="flex flex-col gap-1">
                    <label className="text-sm font-semibold">Phone Number</label>
                    <input
                        type="text"
                        name="userId"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="p-2 rounded-md outline-none border-2 border-gray-300"
                        placeholder="Phone Number"
                        maxLength={10}
                        required
                    />
                </div>

                {
                    isCardBalanceLoading && <Loader />
                }
                
                {
                    !isCardBalanceLoading &&
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                            <label className="text-sm font-semibold">Card Number</label>
                        <input
                            type="text"
                            name="cardNumber"
                            value={formData.cardNumber}
                            onChange={handleChange}
                            className="p-2 rounded-md outline-none border-2 border-gray-300"
                            placeholder="Card Number"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label className="text-sm font-semibold">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="p-2 rounded-md outline-none border-2 border-gray-300"
                            placeholder="Name"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label className="text-sm font-semibold">Balance</label>
                        <input
                            type="number"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            className="p-2 rounded-md outline-none border-2 border-gray-300"
                            placeholder="Amount"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label className="text-sm font-semibold">Cashback Rewards</label>
                        <input
                            type="number"
                            name="redeemPoints"
                            value={formData.redeemPoints}
                            onChange={handleChange}
                            className="p-2 rounded-md outline-none border-2 border-gray-300"
                            placeholder="Redeem Points"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label className="text-sm font-semibold">Chips</label>
                        <input
                            type="number"
                            name="applicableCashback"
                            value={formData.applicableCashback}
                            onChange={handleChange}
                            className="p-2 rounded-md outline-none border-2 border-gray-300"
                            placeholder="Applicable Cashback"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label className="text-sm font-semibold">Is Blocked</label>
                        <select
                            name="isBlocked"
                            value={formData.isBlocked}
                            onChange={handleChange}
                            className="p-2 rounded-md outline-none border-2 border-gray-300"
                            required
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="bg-blue-500 text-white p-2 rounded-md"
                        disabled={isLoading || isUpdateLoading}
                        onClick={isAdded ? handleUpdateCardSubmit : handleCreateNewUserSubmit}
                    >
                        {(isLoading || isUpdateLoading) ? (isAdded ? "Updating..." : "Adding...") : isAdded ? "Update Card" : "Add Card"}
                    </button>
                </div>}

                {(error || updateError) && <p className="text-red-500">Something went wrong</p>}
            </div>
        </div>
    </div>;
};

export default CreateNewUser;

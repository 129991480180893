import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: {},
        totalAmount: 0
    },
    reducers: {
        addItemToCart: (state, action) => {
            const { item } = action.payload;
            const currentQuantity = state.items[item._id]?.quantity || 0;
            state.items[item._id] = {
                item,
                quantity: currentQuantity + 1
            };
            state.totalAmount += item.price;
        },
        removeItemFromCart: (state, action) => {
            const { itemId } = action.payload;
            if(state.items[itemId]) {
                state.totalAmount -= state.items[itemId].item.price * state.items[itemId].quantity;
                delete state.items[itemId];
            }
        },
        clearCart: (state) => {
            state.items = {};
            state.totalAmount = 0;
        },
        updateItemQuantity: (state, action) => {
            const { itemId, quantity } = action.payload;
            if(state.items[itemId]) {
                if(quantity <= 0) {
                    state.totalAmount -= state.items[itemId].item.price * state.items[itemId].quantity;
                    delete state.items[itemId];
                } else {
                    const itemPrice = state.items[itemId].item.price;
                    state.totalAmount += (quantity - state.items[itemId].quantity) * itemPrice;
                    state.items[itemId].quantity = quantity;
                }
            }
        }
    }
});

export const { addItemToCart, removeItemFromCart, clearCart, updateItemQuantity } = cartSlice.actions;
export default cartSlice.reducer;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const orderApi = createApi({
    reducerPath: "orderApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("token");
            if(token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    tagTypes: ["Orders"],
    endpoints: (builder) => ({
        getAllOrdersOfRestaurant: builder.query({
            query: (restaurantId) => `/orders/${restaurantId}`,
            providesTags: ["Orders"]
        }),
        getOrderById: builder.query({
            query: (id) => `/order/${id}`,
            providesTags: ["Orders"]
        }),
        createOrder: builder.mutation({
            query: (order) => ({
                url: "/order",
                method: "POST",
                body: order
            }),
            invalidatesTags: ["Orders"]
        })
    })
})

export const { useGetAllOrdersOfRestaurantQuery, useGetOrderByIdQuery, useCreateOrderMutation } = orderApi;
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import { useGetAllPlayersQuery } from "../redux/services/playerService";

const Players = () => {
    const { data, isLoading, isError } = useGetAllPlayersQuery();
    return <div className="container mx-auto">
        <Navbar />
        {isLoading && <Loader />}
        {isError && <div>Error fetching players</div>}

        <table className="max-w-[1000px] mx-auto">
            <thead>
                <tr className="border-b border-gray-200">
                    <th className="text-left p-3 px-5">S.No</th>
                    <th className="text-left p-3 px-5">ID</th>
                    <th className="text-left p-3 px-5">Name</th>
                    <th className="text-left p-3 px-5">Team</th>
                </tr>
            </thead>
            {data && data.map((player, index) => (
                <tbody>
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                        <td className="p-3 px-5">{index + 1}</td>
                        <td className="p-3 px-5">{player._id}</td>
                        <td className="p-3 px-5">{player.name}</td>
                        <td className="p-3 px-5">{player.team}</td>
                    </tr>
                </tbody>
            ))}
        </table>
    </div>
}

export default Players;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const itemApi = createApi({
    reducerPath: "itemApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("token");
            if(token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    tagTypes: ["Items"],
    endpoints: (builder) => ({
        getAllItemsOfUser: builder.query({
            query: (restaurantId) => `/items/${restaurantId}`,
            providesTags: ["Items"]
        }),
        addItem: builder.mutation({
            query: (item) => ({
                url: "/item",
                method: "POST",
                body: item
            }),
            invalidatesTags: ["Items"]
        })
    })
})

export const { useGetAllItemsOfUserQuery, useAddItemMutation } = itemApi;
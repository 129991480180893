import { createSlice } from "@reduxjs/toolkit";
import { orderApi } from "../services/orderService";

const orderSlice = createSlice({
    name: "order",
    initialState: {
        order: null,
        isLoading: false,
        error: null,
        success: false
    },
    reducers: {
        clearOrderStatus: (state) => {
            state.order = null;
            state.error = null;
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(orderApi.endpoints.createOrder.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(orderApi.endpoints.createOrder.matchFulfilled, (state, action) => {
            state.order = action.payload;
            state.isLoading = false;
            state.success = true;
        })
        builder.addMatcher(orderApi.endpoints.createOrder.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
        })
    }
})

export const { clearOrderStatus } = orderSlice.actions;
export default orderSlice.reducer;
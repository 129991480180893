import Navbar from "../components/Navbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLoginRestaurantMutation } from "../redux/services/userService";
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const [loginUser] = useLoginRestaurantMutation();
    const { isLoading, error } = useSelector((state) => state.user);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await loginUser(formData).unwrap();
            navigate("/");
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <Navbar />
            <div className="flex flex-col gap-4 justify-center items-center p-4">
                <h1 className="text-2xl font-semibold">Login</h1>

                <form className="flex flex-col gap-4 max-w-md w-full" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="p-2 w-full rounded-md outline-none border-2 border-gray-300"
                        placeholder="Email"
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="p-2 w-full rounded-md outline-none border-2 border-gray-300"
                        placeholder="Password"
                        required
                    />
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded-md" disabled={isLoading}>{isLoading ? "Logging in..." : "Login"}</button>
                </form>

                {error && <p className="text-red-500 mt-4">{error?.error || "Something went wrong"}</p>}
            </div>
        </div>
    )
}

export default Login;
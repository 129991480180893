import Navbar from "../components/Navbar";
import { useState } from "react";
import { useRegisterRestaurantMutation } from "../redux/services/userService";

const Register = () => {
    const [formData, setFormData] = useState({
        restaurantName: "",
        ownerName: "",
        email: "",
        password: "",
        phoneNumber: "",
        city: "",
        address: "",
        pincode: "",
    });
    const [registerRestaurant, { isLoading, error }] = useRegisterRestaurantMutation();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await registerRestaurant(formData).unwrap();

            setFormData({
                restaurantName: "",
                ownerName: "",
                email: "",
                password: "",
                phoneNumber: "",
                city: "",
                address: "",
                pincode: "",
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <Navbar />

            <div className="flex flex-col gap-4 justify-center items-center p-4">
                <h1 className="text-2xl font-semibold">Register Restaurant</h1>

                <form action="" className="flex flex-col gap-4 max-w-96 w-full" onSubmit={handleSubmit}>
                    <input type="text" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Restaurant Name" name="restaurantName" value={formData.restaurantName} onChange={handleChange} required />
                    <input type="text" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Owner Name" name="ownerName" value={formData.ownerName} onChange={handleChange} required />
                    <input type="email" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required />
                    <input type="password" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Password" name="password" value={formData.password} onChange={handleChange} required />
                    <input type="text" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                    <input type="text" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="City" name="city" value={formData.city} onChange={handleChange} required />
                    <input type="text" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Address" name="address" value={formData.address} onChange={handleChange} required />
                    <p className="text-sm italic text-gray-500">This address will be printed on the bill</p>
                    <input type="text" className="p-2 w-full rounded-md outline-none border-2 border-gray-300" placeholder="Pincode" name="pincode" value={formData.pincode} onChange={handleChange} required />
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded-md" disabled={isLoading}>{isLoading ? "Registering..." : "Register"}</button>
                </form>

                {error && <p className="text-red-500 mt-4">{error.data.message || "Something went wrong"}</p>}
            </div>
        </div>
    )
}

export default Register;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const baseUrl = "https://dev.fulltoss.shop/api/v1/";

export const cardApi = createApi({
    reducerPath: "cardApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("token");
            if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    } }),
    tagTypes: ["CardBalance", "CardBalanceByPhone", "Card"],
    endpoints: (builder) => ({
        createNewCard: builder.mutation({
            query: (formData) => ({
                url: `/card`,
                method: "POST",
                body: formData
            }),
            invalidatesTags: ["Card"]
        }),
        updateCard: builder.mutation({
            query: ({id, formData}) => ({
                url: `/card/${id}`,
                method: "PUT",
                body: formData
            }),
            invalidatesTags: ["Card"]
        }),
        getCardBalance: builder.query({
            query: (cardNumber) => `/card-balance/${cardNumber}`,
            providesTags: ["CardBalance", "Card"]
        }),
        getCardBalanceByPhone: builder.query({
            query: (phone) => `/card-balance/phone/${phone}`,
            providesTags: ["CardBalanceByPhone", "Card"]
        }),
        payWithMobile: builder.mutation({
            query: ({phone, otp, amount, restaurantId}) => ({
                url: `/pay-with-mobile-number`,
                method: "POST",
                body: { phone, otp, amount, restaurantId }
            }),
            invalidatesTags: ["CardBalance", "CardBalanceByPhone"]
        }),
        generateOtp: builder.mutation({
            query: ({phone}) => ({
                url: "generate-otp",
                method: "POST",
                body: { phone }
            }),
            invalidatesTags: ["CardBalance", "CardBalanceByPhone"]
        }),
        payWithCash: builder.mutation({
            query: ({phone, amount}) => ({
                url: `/pay-with-cash`,
                method: "POST",
                body: { phone, amount }
            }),
            invalidatesTags: ["CardBalance", "CardBalanceByPhone"]
        }),
        payWithCard: builder.mutation({
            query: ({cardNumber, amount, restaurantId}) => ({
                url: `/pay-with-card-number`,
                method: "POST",
                body: { cardNumber, amount, restaurantId }
            }),
            invalidatesTags: ["CardBalance", "CardBalanceByPhone"]
        })
    })
})

export const { useLazyGetCardBalanceQuery, useLazyGetCardBalanceByPhoneQuery, usePayWithMobileMutation, useGenerateOtpMutation, usePayWithCashMutation, usePayWithCardMutation, useCreateNewCardMutation, useUpdateCardMutation } = cardApi;
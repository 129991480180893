import { createSlice } from "@reduxjs/toolkit";
import { cardApi } from "../services/cardService";

const cardSlice = createSlice({
    name: "card",
    initialState: {
        cardDetails: null,
        error: null,
        isLoading: false
    },
    reducers: {
        clearCardStatus: (state) => {
            state.cardDetails = null;
            state.error = null;
        },
        clearCardError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(cardApi.endpoints.getCardBalance.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(cardApi.endpoints.getCardBalance.matchFulfilled, (state, action) => {
            state.cardDetails = action.payload;
            state.isLoading = false;
        })
        builder.addMatcher(cardApi.endpoints.getCardBalance.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
        })
        builder.addMatcher(cardApi.endpoints.getCardBalanceByPhone.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(cardApi.endpoints.getCardBalanceByPhone.matchFulfilled, (state, action) => {
            state.cardDetails = action.payload;
            state.isLoading = false;
        })
        builder.addMatcher(cardApi.endpoints.getCardBalanceByPhone.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
        })
    }
})

export const { clearCardStatus, clearCardError } = cardSlice.actions;
export default cardSlice.reducer;
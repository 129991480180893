import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./services/userService";
import { itemApi } from "./services/itemService";
import { cardApi } from "./services/cardService";
import { orderApi } from "./services/orderService";
import { playerApi } from "./services/playerService";

import cartReducer from "./slices/cartSlice";
import userReducer from "./slices/userSlice";
import cardReducer from "./slices/cardSlice";
import paymentReducer from "./slices/paymentSlice";
import orderReducer from "./slices/orderSlice";
import otpReducer from "./slices/otpSlice";

export const store = configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer,
        card: cardReducer,
        payment: paymentReducer,
        order: orderReducer,
        otp: otpReducer,
        [userApi.reducerPath]: userApi.reducer,
        [itemApi.reducerPath]: itemApi.reducer,
        [cardApi.reducerPath]: cardApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [playerApi.reducerPath]: playerApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        userApi.middleware,
        itemApi.middleware,
        cardApi.middleware,
        orderApi.middleware,
        playerApi.middleware
    ),
})
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useState, useEffect, useRef } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { clearCardStatus, clearCardError } from "../redux/slices/cardSlice";
import { useLazyGetCardBalanceQuery, useLazyGetCardBalanceByPhoneQuery, usePayWithCardMutation, usePayWithMobileMutation, usePayWithCashMutation, useGenerateOtpMutation } from "../redux/services/cardService";
import { useCreateOrderMutation } from "../redux/services/orderService";
import OrderBill from "../components/OrderBill";

const PaymentPage = () => {
    const [paymentMethod, setPaymentMethod] = useState("card");
    const [cardNumber, setCardNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [cashPhone, setCashPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showOtpBtn, setShowOtpBtn] = useState(false);
    const [name, setName] = useState("");
    const [tableNumber, setTableNumber] = useState("");
    const [proceedToPayment, setProceedToPayment] = useState(true);
    const billRef = useRef(null);
    const cardNumberRef = useRef(null);

    const dispatch = useDispatch();
    const cardDetails = useSelector(state => state.card.cardDetails);
    const cartItems = useSelector(state => state.cart.items);
    const restaurantDetails = useSelector(state => state.user.user);
    const [getCardBalance] = useLazyGetCardBalanceQuery();
    const [getCardBalanceByPhone] = useLazyGetCardBalanceByPhoneQuery();
    const [payWithCard] = usePayWithCardMutation();
    const [payWithMobile] = usePayWithMobileMutation();
    const [payWithCash] = usePayWithCashMutation();
    const [generateOtp] = useGenerateOtpMutation();
    const [createOrder] = useCreateOrderMutation();

    const cardError = useSelector(state => state.card.error);
    const isCardBalanceLoading = useSelector(state => state.card.isLoading);
    const isPaymentLoading = useSelector(state => state.payment.isLoading);
    const isOtpLoading = useSelector(state => state.otp.isLoading);
    const otpError = useSelector(state => state.otp.error);
    const otpSuccessMessage = useSelector(state => state.otp.message);
    const paymentSuccess = useSelector(state => state.payment.success);
    const paymentError = useSelector(state => state.payment.error);
    const orderError = useSelector(state => state.order.error);
    const isOrderLoading = useSelector(state => state.order.isLoading);
    const orderSuccess = useSelector(state => state.order.success);
    const totalOrderAmount = useSelector(state => state.cart.totalAmount);

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
        setCardNumber("");
        setMobileNumber("");
        setCashPhone("");
        setOtp("");
        dispatch(clearCardStatus());
    }

    const handleCardNumberChange = (e) => {
        setCardNumber(e.target.value);
    }

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
        if (e.target.value.length === 10 && !isNaN(e.target.value)) {
            setShowOtpBtn(true);
        } else {
            setShowOtpBtn(false);
        }
    }

    const handleCashPhoneChange = (e) => {
        setCashPhone(e.target.value);
    }

    const handleSendOtp = async () => {
        try {
            const newPhone = "+91" + mobileNumber;
            await generateOtp({ phone: newPhone });
            setShowOtpInput(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleOtpChange = (otp) => {
        setOtp(otp);
    }

    const handlePayWithCard = async () => {
        try {
            const res = await payWithCard({ cardNumber, amount: totalOrderAmount, restaurantId: restaurantDetails?.restaurantName });

            if (res?.data?.success) {
                await handleCreateOrder();
            }

            console.log("Payment response card", res);
        } catch (error) {
            console.log(error);
        }
    }

    const handlePayWithMobile = async () => {
        try {
            const res = await payWithMobile({ phone: mobileNumber, otp, amount: totalOrderAmount, restaurantId: restaurantDetails?.restaurantName });

            if (res?.data?.success) {
                await handleCreateOrder();
            }

            console.log("Payment response", res);
        } catch (error) {
            console.log(error);
        }
    }

    const handlePayWithCash = async () => {
        try{
            const res = await payWithCash({ phone: cashPhone, amount: totalOrderAmount });

            if (res?.data?.success) {
                await handleCreateOrder();
            }

            console.log("Payment response cash", res);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCreateOrder = async () => {
        try {
            const res = await createOrder({
                restaurantId: restaurantDetails?._id,
                items: Object.keys(cartItems)?.map(itemId => ({
                    itemId: itemId,
                    itemName: cartItems[itemId].item.name,
                    quantity: cartItems[itemId].quantity,
                    price: cartItems[itemId].item.price
                })),
                totalAmount: totalOrderAmount,
                tableNumber: tableNumber,
                name: name,
                phoneNumber: cardDetails?.card?.userId,  // change for mobile and cash
                paymentMethod: paymentMethod,
                paymentAmount: paymentMethod === "cash" ? totalOrderAmount : ((totalOrderAmount > cardDetails?.card?.redeemPoints) ? (totalOrderAmount - cardDetails?.card?.redeemPoints) : 0),
                points: paymentMethod === "cash" ? 0 : (cardDetails?.card?.redeemPoints > totalOrderAmount ? (cardDetails?.card?.redeemPoints - totalOrderAmount) : 0),
                chips: paymentMethod === "cash" ? cardDetails?.card?.applicableCashback + totalOrderAmount : (
                    cardDetails?.card?.applicableCashback + ((totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0)
                ),
                status: "placed",
                discount: paymentMethod === "cash" ? 0 : (cardDetails?.card?.redeemPoints > totalOrderAmount ? totalOrderAmount : cardDetails?.card?.redeemPoints)
            });
            console.log("Order response", res);
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownloadBill = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head>');
        printWindow.document.write('<script src="https://cdn.tailwindcss.com"></script>');
        printWindow.document.write('</head><body>');
        printWindow.document.write(billRef.current.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    useEffect(() => {
        if (paymentMethod === "card" && cardNumberRef.current) {
            cardNumberRef.current.focus();
        }
    }, [paymentMethod, cardNumberRef]);

    useEffect(() => {
        if (cardNumber.length === 10 && !isNaN(cardNumber)) {
            getCardBalance(cardNumber);
        }
        else if (mobileNumber.length === 10 && !isNaN(mobileNumber)) {
            getCardBalanceByPhone(mobileNumber);
        } else if (cashPhone.length === 10 && !isNaN(cashPhone)) {
            getCardBalanceByPhone(cashPhone);
        } else {
            dispatch(clearCardStatus());
            dispatch(clearCardError());
        }
    }, [cardNumber, mobileNumber, cashPhone, getCardBalance, getCardBalanceByPhone, dispatch]);

    return (
        <div className="">
            <Navbar />
            {
                Object.keys(cartItems).length === 0 ? (
                    <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
                        <p className="text-2xl font-semibold">Your cart is empty</p>
                    </div>
                ) : (
                    <div className="flex flex-col justify-center p-4 w-[600px] border border-gray-300 rounded-md mx-auto my-4">
                        <p className="text-2xl font-semibold">Payment</p>
                        <hr className="my-2" />
                        <div className="flex gap-2 my-2">
                            <input type="text" className="w-72 p-2 border border-gray-300 rounded-md outline-none" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name" />
                            <input type="text" className="w-72 p-2 border border-gray-300 rounded-md outline-none" value={tableNumber} onChange={(e) => setTableNumber(e.target.value)} placeholder="Table Number" />
                        </div>

                        {proceedToPayment && <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={() => setProceedToPayment(false)}>Proceed to Payment</button>}
                        {!proceedToPayment && <div className="flex flex-col gap-2">
                            <p className="text-lg font-medium mt-2">Total Amount: ₹ {totalOrderAmount}</p>
                            <p className="mt-2">Choose Payment Method</p>

                            <div className="flex gap-2 mb-2">
                                <button className={`border-2 border-blue-500 px-3 py-1 rounded-md ${paymentMethod === "card" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => handlePaymentMethodChange("card")}>Fulltoss Card</button>
                                <button className={`border-2 border-blue-500 px-3 py-1 rounded-md ${paymentMethod === "mobile" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => handlePaymentMethodChange("mobile")}>Fulltoss Mobile Wallet</button>
                                <button className={`border-2 border-blue-500 px-3 py-1 rounded-md ${paymentMethod === "cash" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => handlePaymentMethodChange("cash")}>Cash</button>
                                <button className={`border-2 border-blue-500 px-3 hidden py-1 rounded-md ${paymentMethod === "upi" ? "bg-blue-500 text-white" : "bg-white text-blue-500"}`} onClick={() => handlePaymentMethodChange("upi")}>UPI</button>
                            </div>

                            {
                                paymentMethod === "card" &&
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-between gap-2">
                                        <input type="text" className="w-72 p-2 border border-gray-300 rounded-md outline-none" placeholder="Card Number" value={cardNumber} onChange={handleCardNumberChange} ref={cardNumberRef} />
                                    </div>

                                    {
                                        isCardBalanceLoading &&
                                        <Loader />
                                    }

                                    {
                                        !isCardBalanceLoading && cardDetails &&
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col w-fit justify-between gap-0.5 aspect-[2/1] bg-blue-300 p-4 rounded-md">
                                                <p className="flex justify-between">Balance: <span>₹ {cardDetails.card.amount}</span></p>
                                                <p className="flex justify-between">Reward Points: <span>₹ {cardDetails.card.redeemPoints}</span></p>
                                                <p className="flex justify-between">Chips: <span>{cardDetails.card.applicableCashback}</span></p>
                                            </div>
                                            {!orderSuccess && <p>Updated Chips will be: ₹ ({cardDetails?.card.applicableCashback} + {(totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0}) = {(cardDetails?.card.applicableCashback + ((totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0))}</p>}

                                            <hr className="my-2" />

                                            {!paymentSuccess &&
                                                <div className="flex flex-col gap-1">
                                                    <p className="flex justify-between">Reward Points deducted: <span className="font-medium">₹ {totalOrderAmount > cardDetails.card.redeemPoints ? cardDetails.card.redeemPoints : totalOrderAmount}</span></p>
                                                    <p className="flex justify-between">Net balance to be deducted: <span className="font-medium">₹ {(totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? totalOrderAmount - cardDetails.card.redeemPoints : 0}</span></p>

                                                    <hr className="my-2" />

                                                    <p className="flex justify-between">Total amount to be paid: <span className="font-medium">= ₹ {totalOrderAmount}</span></p>
                                                </div>}
                                        </div>
                                    }
                                    <button className={`bg-blue-500 text-white px-4 py-2 rounded-md ${isCardBalanceLoading || !cardDetails || isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess ? "opacity-50 cursor-not-allowed" : ""}`} disabled={isCardBalanceLoading || !cardDetails || isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess} onClick={handlePayWithCard}>Pay via Card</button>
                                </div>
                            }

                            {
                                paymentMethod === "mobile" &&
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-between gap-2">
                                        <input type="number" className="w-72 p-2 border border-gray-300 rounded-md outline-none" value={mobileNumber} onChange={handleMobileNumberChange} placeholder="Enter 10 digit phone number" disabled={showOtpInput} />
                                        {showOtpBtn && !showOtpInput && !cardError && <button className={`bg-blue-500 text-white px-4 py-1 rounded-md ${isCardBalanceLoading ? "opacity-50 cursor-not-allowed" : ""}`} disabled={isCardBalanceLoading} onClick={handleSendOtp}>{isOtpLoading ? "Sending OTP..." : "Send OTP"}</button>}
                                    </div>
                                    {/* <p className="text-sm text-gray-500 p-0 m-0">Resend OTP in 00:59</p> */}

                                    {showOtpInput && <OTPInput
                                        value={otp}
                                        onChange={handleOtpChange}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        renderInput={(props) => <input {...props} disabled={isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess} />}
                                        inputStyle={{ width: '36px', height: '36px', border: '1px solid #ccc', borderRadius: '5px', margin: '0 2px' }}
                                    />}

                                    {
                                        otpError &&
                                        <p className="text-red-500">{otpError}</p>
                                    }
                                    {
                                        otpSuccessMessage &&
                                        <p className="text-green-500">{otpSuccessMessage}</p>
                                    }

                                    {
                                        isCardBalanceLoading &&
                                        <Loader />
                                    }

                                    {
                                        !isCardBalanceLoading && cardDetails &&
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col w-fit justify-between gap-0.5 aspect-[2/1] bg-blue-300 p-4 rounded-md">
                                                <p className="flex justify-between">Balance: <span>₹ {cardDetails.card.amount}</span></p>
                                                <p className="flex justify-between">Past Reward Points: <span>₹ {cardDetails.card.redeemPoints}</span></p>
                                                <p className="flex justify-between">Past Chips: <span>{cardDetails.card.applicableCashback}</span></p>
                                            </div>
                                            {!orderSuccess && <p>Updated Chips will be: ₹ ({cardDetails.card.applicableCashback} + {(totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0}) = {(cardDetails?.card.applicableCashback + ((totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0))}</p>}

                                            <hr className="my-2" />

                                            {!paymentSuccess && <div className="flex flex-col gap-1">
                                                <p className="flex justify-between">Reward Points deducted: <span className="font-medium">₹ {totalOrderAmount > cardDetails.card.redeemPoints ? cardDetails.card.redeemPoints : totalOrderAmount}</span></p>
                                                <p className="flex justify-between">Net balance to be deducted: <span className="font-medium">₹ {(totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? totalOrderAmount - cardDetails.card.redeemPoints : 0}</span></p>

                                                <hr className="my-2" />

                                                <p className="flex justify-between">Total amount to be paid: <span className="font-medium">= ₹ {totalOrderAmount}</span></p>
                                            </div>}
                                        </div>
                                    }
                                    <button className={`bg-blue-500 text-white px-4 py-2 rounded-md ${isCardBalanceLoading || !cardDetails || isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess || otp.length !== 6 ? "opacity-50 cursor-not-allowed" : ""}`} disabled={isCardBalanceLoading || !cardDetails || isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess || otp.length !== 6} onClick={handlePayWithMobile}>Pay via Mobile</button>
                                </div>
                            }

                            {/* {
                                paymentMethod === "cash" &&
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-between gap-2">
                                        <input type="number" className="w-72 p-2 border border-gray-300 rounded-md outline-none" placeholder="Enter 10 digit phone number" />
                                        <button className="bg-blue-500 text-white px-4 py-1 rounded-md">Send OTP</button>
                                    </div>
                                    <p className="text-sm text-gray-500 p-0 m-0">Resend OTP in 00:59</p>

                                    <OTPInput
                                        value={"123456"}
                                        // onChange={handleOtpChange}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        renderInput={(props) => <input {...props} />}
                                        inputStyle={{ width: '36px', height: '36px', border: '1px solid #ccc', borderRadius: '5px', margin: '0 2px' }}
                                    />

                                    <div className="flex flex-col gap-1">
                                        <div className="flex flex-col w-fit justify-between gap-0.5 aspect-[2/1] bg-blue-300 p-4 rounded-md">
                                            <p className="flex justify-between">Balance: <span>₹ 999</span></p>
                                            <p className="flex justify-between">Past Reward Points: <span>₹ 0</span></p>
                                            <p className="flex justify-between">Past Chips: <span>0</span></p>
                                        </div>
                                        <p>Updated Chips will be: ₹ (0 + 999) = 999</p>

                                        <hr className="my-2" />

                                        <p className="flex justify-between">Reward Points deducted: <span className="font-medium">₹ 999</span></p>
                                        <p className="flex justify-between">Net balance to be deducted: <span className="font-medium">₹ 0</span></p>

                                        <hr className="my-2" />

                                        <p className="flex justify-between">Total amount to be paid: <span className="font-medium">= ₹ 999</span></p>
                                    </div>
                                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Pay via Mobile</button>
                                </div>
                            } */}

                            {
                                paymentMethod === "cash" &&
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-between gap-2">
                                        <input type="text" className="w-72 p-2 border border-gray-300 rounded-md outline-none" placeholder="Phone Number" value={cashPhone} onChange={handleCashPhoneChange} />
                                    </div>

                                    {
                                        isCardBalanceLoading &&
                                        <Loader />
                                    }

                                    {
                                        !isCardBalanceLoading && cardDetails &&
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col w-fit justify-between gap-0.5 aspect-[2/1] bg-blue-300 p-4 rounded-md">
                                                <p className="flex justify-between">Balance: <span>₹ {cardDetails.card.amount}</span></p>
                                                <p className="flex justify-between">Reward Points: <span>₹ {cardDetails.card.redeemPoints}</span></p>
                                                <p className="flex justify-between">Chips: <span>{cardDetails.card.applicableCashback}</span></p>
                                            </div>
                                            {!orderSuccess && <p>Updated Chips will be: ₹ ({cardDetails?.card.applicableCashback} + {(totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0}) = {(cardDetails?.card.applicableCashback + ((totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? (totalOrderAmount - cardDetails.card.redeemPoints) : 0))}</p>}

                                            <hr className="my-2" />

                                            {!paymentSuccess &&
                                                <div className="flex flex-col gap-1">
                                                    <p className="flex justify-between">Reward Points deducted: <span className="font-medium">₹ {totalOrderAmount > cardDetails.card.redeemPoints ? cardDetails.card.redeemPoints : totalOrderAmount}</span></p>
                                                    <p className="flex justify-between">Net balance to be deducted: <span className="font-medium">₹ {(totalOrderAmount - cardDetails.card.redeemPoints) > 0 ? totalOrderAmount - cardDetails.card.redeemPoints : 0}</span></p>

                                                    <hr className="my-2" />

                                                    <p className="flex justify-between">Total amount to be paid: <span className="font-medium">= ₹ {totalOrderAmount}</span></p>
                                                </div>}
                                        </div>
                                    }
                                    <button className={`bg-blue-500 text-white px-4 py-2 rounded-md ${isCardBalanceLoading || isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess ? "opacity-50 cursor-not-allowed" : ""}`} disabled={isCardBalanceLoading || isPaymentLoading || paymentSuccess || isOrderLoading || orderSuccess} onClick={handlePayWithCash}>Pay via Cash</button>
                                </div>
                            }

                            {
                                paymentMethod === "upi" &&
                                <div className="text-center">
                                    <p>UPI Payment is not available yet.</p>
                                </div>
                            }

                            {
                                cardError &&
                                <p className="text-red-500">{cardError}</p>
                            }
                            {
                                paymentSuccess &&
                                <p className="text-green-500">Payment successful</p>
                            }
                            {
                                paymentError &&
                                <p className="text-red-500">{paymentError}</p>
                            }
                            {
                                orderError &&
                                <p className="text-red-500">{orderError}</p>
                            }
                            {
                                orderSuccess &&
                                <p className="text-green-500">Order placed successfully</p>
                            }
                            <div className="hidden">
                                <OrderBill ref={billRef} />
                            </div>
                            {orderSuccess && <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleDownloadBill}>Download Bill</button>}
                        </div>}
                    </div>
                )
            }
        </div>
    )
}

export default PaymentPage;
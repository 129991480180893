import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 12,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    section: {
        margin: 10,
        padding: 10,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        marginTop: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
    },
    tableCell: {
        padding: 5,
        borderRightWidth: 1,
        borderRightColor: 'black',
        flexGrow: 1,
    },
    total: {
        marginTop: 10,
        fontSize: 14,
        fontWeight: 'bold',
    },
});

const PDFBill = ({ billData }) => {
    return (
        <Document>
            <Page size="A5" style={styles.page}>
                <View style={styles.section}>
                    <Text style={{ textAlign: 'center', fontSize: 16 }}>{billData?.restaurantName}</Text>
                    <Text style={{ textAlign: 'center' }}>Mobile: {billData?.phoneNumber} | Email: {billData?.email}</Text>
                    <Text style={{ textAlign: 'center' }}>Order No: {billData?.orderId}</Text>
                    <Text style={{ textAlign: 'center' }}>Date: {new Date(billData?.orderDate).toLocaleDateString()} | Time: {new Date(billData?.orderDate).toLocaleTimeString()}</Text>
                </View>
                <View style={styles.table}>
                    {/* Top Row */}
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>S.No</Text>
                        <Text style={styles.tableCell}>Item</Text>
                        <Text style={styles.tableCell}>Quantity</Text>
                        <Text style={styles.tableCell}>Price</Text>
                        <Text style={styles.tableCell}>Subtotal</Text>
                    </View>
                    {billData?.items && Object.keys(billData?.items).length > 0 && Object.keys(billData?.items).map((itemId, index) => (
                        <View style={styles.tableRow} key={itemId}>
                            <Text style={styles.tableCell}>{index + 1}</Text>
                            <Text style={styles.tableCell}>{billData?.items[itemId]?.item?.name}</Text>
                            <Text style={styles.tableCell}>{billData?.items[itemId]?.quantity}</Text>
                            <Text style={styles.tableCell}>₹{billData?.items[itemId]?.item?.price}</Text>
                            <Text style={styles.tableCell}>₹{billData?.items[itemId]?.quantity * billData?.items[itemId]?.item?.price}</Text>
                        </View>
                    ))}
                </View>
                <Text style={styles.total}>Total: ₹{billData?.totalAmount}</Text>
                <Text style={{ textAlign: 'center', marginTop: 20 }}>Powered by Fulltoss POS</Text>
            </Page>
            <div className="bill flex flex-col gap-4">
                <div className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow-lg">
                    <h1 className="text-xl font-bold text-center">Mumbai Tandoor and Grill</h1>
                    <p className="text-center font-medium">Amravati, Maharashtra, India</p>
                    <p className="text-center">+91 98250 00000</p>
                    <p className="text-center">mumbaitempandoor@gmail.com</p>
                    <p className="text-center mt-2 font-medium">Token No: 20</p>
                    <hr className="my-2 border-b-[1px] border-b-black" />
                    <div>
                        <p className="flex justify-between">Name: <span className="font-medium">Nitesh</span></p>
                        <p className="flex justify-between">Date: <span className="font-medium">{new Date().toLocaleDateString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}, {new Date().toLocaleTimeString("en-IN", { hour: '2-digit', minute: '2-digit' })}</span></p>
                        <p className="flex justify-between">Table Number: <span className="font-medium">1</span></p>
                        <p className="flex justify-between">Mobile No: <span className="font-medium">9825000000</span></p>
                    </div>
                    <hr className="my-2 border-b-[1px] border-b-black" />
                    <table className="w-full text-left">
                        <thead className="border-b-[1px] border-b-black">
                            <tr>
                                <th className="font-medium">Item Name</th>
                                <th className="font-medium">Qty</th>
                                <th className="font-medium">Rate</th>
                                <th className="font-medium">Amt</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-1">Paneer Sandwich</td>
                                <td className="py-1">2</td>
                                <td className="py-1">100</td>
                                <td className="py-1">200</td>
                            </tr>
                            <tr>
                                <td className="py-1">Paneer Tikka</td>
                                <td className="py-1">1</td>
                                <td className="py-1">150</td>
                                <td className="py-1">150</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="my-2 border-b-[1px] border-b-black" />
                    <div className="flex justify-between">
                        <p>Total Qty: <span className="font-medium">3</span></p>
                        <p>Subtotal: <span className="font-medium">₹ 350</span></p>
                    </div>
                    <hr className="my-4" />
                    <h2 className="flex justify-between font-bold">Grand Total: <span className="font-medium">₹ 350</span></h2>
                    <hr className="my-4" />
                    <div className="flex justify-between">
                        <p>Rewards Remaining: <span className="font-medium">₹ 50</span></p>
                        <p>Total Chips: <span className="font-medium">30</span></p>
                    </div>
                    <hr className="my-4" />
                    <div className="flex flex-col items-center gap-2">
                        <p className="font-medium">Scan QR Code for Prediction</p>
                        <img src="./qrcode.png" alt="QR Code" className="w-24 h-24 mx-auto" />
                        <p>Printed On: {new Date().toLocaleDateString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}, {new Date().toLocaleTimeString("en-IN", { hour: '2-digit', minute: '2-digit' })}</p>
                    </div>
                    <p className="text-center text-sm mt-2">Powered By: www.fulltoss.club</p>
                </div>

                <div className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow-lg">
                    <p className="text-center text-lg font-bold">Kitchen</p>
                    <p className="text-center text-xl mt-2 font-bold">Token No: 20</p>
                    <hr className="my-2 border-b-[1px] border-b-black" />
                    <div>
                        <p className="flex justify-between">Name: <span className="font-medium">Nitesh</span></p>
                        <p className="flex justify-between">Table Number: <span className="font-medium">1</span></p>
                    </div>
                    <hr className="my-2 border-b-[1px] border-b-black" />
                    <table className="w-full text-left">
                        <thead className="border-b-[1px] border-b-black">
                            <tr>
                                <th className="font-medium">Item Name</th>
                                <th className="font-medium">Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-1">Paneer Sandwich</td>
                                <td className="py-1">2</td>
                            </tr>
                            <tr>
                                <td className="py-1">Paneer Tikka</td>
                                <td className="py-1">1</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="my-2 border-b-[1px] border-b-black" />
                    <div className="flex justify-between">
                        <p>Total Items: <span className="font-medium">2</span></p>
                        <p>Total Qty: <span className="font-medium">3</span></p>
                    </div>
                    <hr className="my-2" />
                    <p className="text-center text-sm mt-2">Powered By: www.fulltoss.club</p>
                </div>
            </div>
        </Document>
    );
};

export default PDFBill;

import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../services/userService";
import Cookies from "js-cookie";

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        clearUser: (state) => {
            state.user = null;
            state.isLoading = false;
            state.error = null;
            Cookies.remove("token");
            Cookies.remove("restaurantId");
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.loginRestaurant.matchPending, (state, action) => {
            state.isLoading = true;
        })
        builder.addMatcher(userApi.endpoints.loginRestaurant.matchFulfilled, (state, action) => {
            state.user = action.payload.user;
            state.isLoading = false;
            Cookies.set("token", action.payload.token, { expires: 1, secure: true });
            Cookies.set("restaurantId", action.payload.restaurantId, { expires: 1, secure: true });
        })
        builder.addMatcher(userApi.endpoints.loginRestaurant.matchRejected, (state, action) => {
            state.error = action.payload?.data?.message || "Something went wrong";
            state.isLoading = false;
        })
    }
})

export const { clearUser, setUser } = userSlice.actions;
export default userSlice.reducer;